.blocker {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(150, 150, 150, 0.6);
    z-index: 60;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.loading-blocker {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 70;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (min-width: 768px) { 
}
@media (min-width: 1024px) { 
}